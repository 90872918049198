<template lang="pug">

.mainBody(v-if="true && !loading" style="overflow-wrap: break-word; min-width:1000px;" onfocusout="")
    //- b-modal#paynowQR(title="Payment Instructions" size="2xl" hide-footer style="min-width:1190px;" :no-close-on-backdrop="true" :no-close-on-esc="true" hide-header-close)
    //-     div.my-3(class="text-center qrcode")
    //-         figure(style="display: flex; justify-content: center; align-items: center;")
    //-             vue-qrcode(:value="paynowString" :options="qroptions")
    //-             img(src="@/assets/new_paynow.png")
    //-         h5.text-muted(style="font-weight:500;")
    //-             span REF: {{invoice?.id}}
    //-     div.topbar
    //-         p 1. Scan the QR code with a mobile banking application.
    //-         p 2. Or upload QR code to a mobile banking application.
    //-         p 3. Click 'Done' only once payment has been made, payment will be reflected within 5 minutes.
    //-     div.my-3
    //-         b-button(variant="success" @click="back" style="width:100%;") Done


    div.topbar.pt-4(v-if='!qrModal')
        b-button.float.px-5.mx-5(@click="back" style="height: 4vh;left: 50px;") Back
        b-button.float.px-5.mx-5(@click="printinv" style="height: 4vh;right: 50px;" ) Save / Print
            b-icon.ml-3(icon="download")
    b-container.bg-white.p-5.mb-5.d-flex.flex-column
        div.px-5.mb-auto
            h1.text-center.font-weight-bold Tax Invoice
            img(class="w-25" src="@/assets/new_dsg-logo.jpg")
            div.my-5
                p.mb-0 Dementia Singapore Ltd,
                p.mb-0 20 Bendemeer Road,
                p.mb-0 #01-02 BS Bendemeer Centre,
                p.mb-0 Singapore 339914
                p.mb-0 GST Reg No. 202111519K
            p.h4.text-left.font-weight-bold Bill To
            div.d-flex.justify-content-around
                b-col.bg-light.p-3.rounded
                    p.mb-0 {{invoice?.client_name}}
                    p.mb-0 {{invoice?.client_address_line1}}
                    p.mb-0 {{invoice?.client_address_line2}}
                    p.mb-0 Singapore {{invoice?.client_address_postal}}
                b-col.pl-4
                    dl.row.mb-1
                        dt.col-4 Invoice No:
                        dd.col-8 {{invoice?.id}}
                    dl.row.mb-1
                        dt.col-4 Date:
                        dd.col-8 {{formattedDate}}
                    dl.row.mb-1
                        dt.col-4 Due Date: 
                        dd.col-8 {{ formattedDueDated }}
                    dl.row.mb-1
                        dt.col-4 Terms:
                        dd.col-8 30 Days
            b-table-simple.mt-3
                b-thead(variant="dark")
                    b-tr
                        b-th.col-6 Description
                        b-th.col-1 QTY
                        b-th.col-1 RATE
                        b-th.col-3.text-right AMOUNT
                b-tbody
                    b-tr
                        b-td(v-html="invoice?.description")
                        b-td {{invoice?.qty}}
                        b-td {{invoice?.amount - (invoice?.subsidy || 0) }}
                        b-td.text-right {{(invoice?.amount - invoice?.subsidy || 0 ) * invoice?.qty}}
            b-row
                b-col(cols="4" offset="8")
                    b-table-simple
                        b-tbody
                            b-tr
                                b-td SUBTOTAL (SGD)
                                b-td.text-right {{((invoice?.amount - invoice?.subsidy || 0 ) * invoice?.qty).toFixed(2)}}
                            b-tr
                                b-td GST TOTAL (SGD)
                                b-td.text-right {{(((invoice?.amount - invoice?.subsidy || 0 ) * invoice?.qty) * (invoice?.gst / 100)).toFixed(2)}}
                            b-tr
                                b-td.font-weight-bold TOTAL (SGD)
                                b-td.text-right {{((parseFloat((invoice?.amount - invoice?.subsidy || 0 )) + parseFloat(((invoice?.amount - invoice?.subsidy || 0 ) * (invoice?.gst / 100)).toFixed(2))) * parseFloat(invoice?.qty)).toFixed(2)}}
        h5.text-center.mt-5 This is a computer-generated invoice. No signature is required.
        p.text-center.mt-auto 1 of 2
    b-container.bg-white.p-5.mb-5.d-flex.flex-column(style="min-height:1400px;")
        div.px-5
            h1.text-right.font-weight-bold.mb-4 Statement Balance as of {{now}}
            b-table-simple.small
                b-thead.bg-light
                    b-tr
                        b-th(style="width:100%")
                        b-th.text-right Amount (SGD)
                b-tbody
                    //- b-tr
                    //-     b-td.text-right.border-0 Invoice Amount:
                    //-     b-td.text-right.border-0 {{invoice?.amount * invoice?.qty}}
                    //- b-tr
                    //-     b-td.text-right.border-0 LESS Payment on 15-Oct-2023:
                    //-     b-td.text-right.border-0 (150.00)
                    //- b-tr
                    //-     b-td.text-right.border-0 LESS Credit Note on 20-Oct-2023:
                    //-     b-td.text-right.border-0 (50.00)
                    b-tr(v-for="payment in invoice?.list_payment")
                        b-td.text-right.border-0 Payment on {{formatDate(payment.date)}}:
                        b-td.text-right.border-0 (${{payment.amt}})
                    b-tr(v-for="credit in invoice?.list_cn")
                        b-td.text-right.border-0 Credit Note on {{formatDate(credit.date)}}:
                        b-td.text-right.border-0 (${{credit.amt}})
                    b-tr
                        b-td.text-right.border-0.font-weight-bold Outstanding Balance
                        b-td.text-right.border-0.font-weight-bold {{outstandingAmount(invoice)}}
            div.my-3(v-if="!invoice?.list_payment") 
                h1(style="font-weight:700;") Payment Instructions
                h4.text-danger(style="font-weight:700;") Payment via QR code is highly recommended.
                p Kindly make payment by scanning the PayNow QR code below with a mobile banking application, or making an electronic funds transfer (FAST), to the bank account below.
                hr
                p.my-2.text-danger IMPORTANT: For electronic funds transfer, please indicate invoice number as payment reference.
                h4.text-danger(style="font-weight:700; text-decoration:underline;")
                    u {{invoice?.id}}
            div.d-flex.px-5.mx-2.my-4(v-if="!invoice?.list_payment") 
                b-table-simple.small.borderless(style="max-width:50%;")
                    b-tr
                        b-td Bank account name:
                        b-td Dementia Singapore Ltd - Acc 1
                    b-tr
                        b-td Bank account number:
                        b-td 451-312-912-7
                    b-tr
                        b-td UEN for PayNow:
                        b-td 202111519KDSG
                    b-tr
                        b-td Bank:
                        b-td United Overseas Bank Limited
                    b-tr
                        b-td Branch code:
                        b-td 001
                    b-tr
                        b-td Bank address:
                        b-td
                            | 80 Raffles Place
                            br
                            | Singapore 048624
                    b-tr
                        b-td Bank code:
                        b-td 7375
                    b-tr
                        b-td Bank SWIFT Code:
                        b-td UOVBSGSG
                div(class="ml-auto qrcode")
                    vue-qrcode(:value="paynowString" :options="{ width: 128, height: 128 }")
                        img(src="@/assets/new_paynow.png")
            hr
            div(v-if="!invoice?.list_payment") 
                p For Cheque Payment:
                p.mb-1 All cheques should be crossed and made payable to
                    b.text-danger.ml-1 Dementia Singapore Ltd - Acc 1
                p Kindly indicate name of the client and invoice number at the back of the cheque.
        h5.text-center.mt-5 End of document 
        p.text-center.mt-auto 2 of 2

        b-button.topbar(v-if='outstandingAmount(invoice) > 0 && !qrModal' style="width:90%; height: 6.2vh; bottom:50px;background-color:rgb(19, 206, 159);" @click="openQR").float Click here to Pay
         b-icon.ml-3(icon="cash")
            

div(v-else class="d-flex w-100 h-100 flex-column align-items-center p-2")
    b-skeleton(height="8vh" width="100%" class="my-2")    
        
    b-skeleton(height="60vh" width="100%" class="my-2" v-for="i in 2")
</template>



<script>
import dayjs from "dayjs"
import VueQrcode from '@chenfengyuan/vue-qrcode';
import PaynowQR from 'paynowqr'
import axios from 'axios'
export default{
    components: {
        VueQrcode
    },
    data(){
        return{
            now: dayjs().format("DD MMMM YYYY"),
            invoice:{},
            loading: false,
            qrModal: false,

        }
    },
    computed: {
        formattedDueDated() {
            return this.invoice?.date ? dayjs(this.invoice.date).add(30, 'days').format("DD-MM-YYYY") : '';
        },
        formattedDate() {
            return this.invoice?.date ? dayjs(this.invoice.date).format("DD-MM-YYYY") : '';
        },
        paynowString() {
            
            let qrcode = new PaynowQR({
                uen: '202111519KDSG',           //Required: UEN of company
                amount: this.outstandingAmount(this.invoice),  //Required: Amount to pay
                refNumber: this.invoice.id,   //Reference number for Paynow Transaction. Useful if you need to track payments for recouncilation.
                company: 'Dementia Singapore Ltd. - Acc 1'   //Company name to embed in the QR code. Optional.               
            });
            return qrcode.output();
        },
        qroptions() {
            const width = window.innerWidth > 1200 ? 400 : 250;
            const height = window.innerWidth > 1200 ? 400 : 250;
            return {
            errorCorrectionLevel: "H",
            color: {
                dark: "#7C1978"
            },
            width: width,
            height: height
            }
        }
    },
    methods:{
        outstandingAmount(invoice){
            const totalPayments = invoice?.list_payment?.reduce((sum, payment) => sum + payment.amt, 0) || 0;
            const totalCredits = invoice?.list_cn?.reduce((sum, credit) => sum + credit.amt, 0) || 0;
            const totalPaidAmount = totalPayments + totalCredits;
            return ((parseFloat((invoice?.amount - invoice?.subsidy || 0 )) + parseFloat(((invoice?.amount - invoice?.subsidy || 0 ) * (invoice?.gst / 100)).toFixed(2))) * parseFloat(invoice?.qty) - totalPaidAmount).toFixed(2)
        },
        formatDate(date){
            return dayjs(date).format("DD-MMM-YYYY")
        },
        async openQR(){
            this.$router.replace(`/payment?id=${this.invoice.guid}`);
            // this.qrModal = !this.qrModal;
            // document.querySelectorAll('meta')[2].setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no')
            // this.$bvModal.show('paynowQR')
        },
        async back(){
            // this.qrModal = !this.qrModal;
            await document.querySelectorAll('.mainBody').forEach(e => e.style.display = 'none')
            document.querySelectorAll('meta')[2].setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no')
            document.title = 'DSG FOW Caregiver'
            this.$router.replace('/invoices')
        },
        screenChange(){
            document.querySelectorAll('meta')[2].setAttribute('content', '')
        },
        printinv() {
            document.title = `Invoice-${this.invoice?.id}`;
            window.print()
        },
        getQueryParams(){
            const params = new URLSearchParams(window.location.search)
            // console.log(params.get('id'))
            return params.get('id')
        },
        getInvoiceWithID(id){
            if(!id) return
            this.loading = true
            axios.get(`/api/invoices/single/${id}`)
            .then(res => {
                // console.log('invoice',res.data)
                this.invoice = res.data
            }).catch(e => {
                console.error(e)
            }).finally(() => {
                this.loading = false
            })
            
        }
    },
    mounted(){
      const id= this.getQueryParams()
    //   console.log(id)
        this.getInvoiceWithID(id)

    },
    // inject: ['invoices'],
    created(){
        this.screenChange();
    }
}
</script>

<style lang="scss" scoped>
.container {
    min-height: 1400px;
    min-width: 1044px !important;
}

.qrcode {
    position: relative;
}

.qrcode img {
    height: 20%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
.float{
    position:fixed;
	background-color:rgba(73, 75, 74, 0.721);
	color:#ffffff;
	border-radius:30px;
    border: none;
	text-align:center;
    font-size:2rem;
    z-index: 9999;
}

.topbar {
    background-color: #00000000;
}

@media print {
    .container {
        break-after: always;
        break-inside: avoid;
    }

    .topbar,
    .topbar * {
        display: none !important;
        ;
    }

    .container,
    .container * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
}
</style>