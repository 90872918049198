<template lang="pug">
div(v-if="$route.path !=='/invoice_single'")
    b-navbar( class="navbar p-3" toggleable="md")
        b-icon(icon="list" variant="white" v-b-toggle.nav_collapse class="navbar-toggler" v-if="!$root.changingClient && caregiverName && $route.name !== 'EventDetails'")
        b-icon(icon="chevron-left" variant="white" v-if="!$root.changingClient && caregiverName && $route.name === 'EventDetails'"
        class="back-icon" @click="$router.replace('/events')")
        
        //- div(class="welcome-text" v-if="!$root.changingClient && caregiverName") 
        //-     p.m-0 Good {{ timeOfDayGreet }}, {{caregiverName}}
        div(class="welcome-text ml-4" v-if="!$root.changingClient && caregiverName") 
            p.m-0.h5() {{ $route.path === '/home' ? 'Home' : $route.path === '/session' ? 'Past Sessions' : $route.path === '/announcement' ? 'Announcement' : $route.path === '/contact' ? 'Contact Us' : $route.path === '/survey' ? 'Survey' : $route.path === '/messaging' ? 'Chat With Admin' : $route.path === '/events' ? 'Events' : $route.path.includes('/event/') ? 'Event Details' : $route.path === '/settings' ? 'Settings': $route.path === '/invoices' ? 'Invoices' : '' }}
        //- div(class="skeleton-loader" v-else)
        //-     div.skeleton-text 
        div(class="w-50 h-75" v-if="$root.changingClient || !caregiverName")
            b-skeleton(variant="text",  class="w-100 h-100")
        //- div.skeleton-avatar(v-if="$root.changingClient || !caregiverName" )
        div(class="avatar-skleton" v-if="$root.changingClient || !caregiverName" class="h-75 rounded-circle" style="width: 40px;")
            b-skeleton(variant="circle", class="w-100 h-100 rounded-circle")
        b-avatar(variant="info",  v-b-modal.modal-1, :src="`data:image/png;base64,${currentUserPhoto}`", v-if="!$root.changingClient && caregiverName" class="ml-auto" style="cursor: pointer")
        
        b-sidebar(to="/sidebar" toggleable="md" id="nav_collapse"
        backdrop no-header)
            div(class="sidebar-header")
                img(src="../assets/DSGlogo.png")
                b-icon(icon="X"  v-b-toggle.nav_collapse class="icon-close")
            div(class="sidebar-body")
                div(class="links-container")
                    div(v-for="link in navLinks" :key="link.name" class="sidebar-link shadow-sm"  :class="{'active text-white': $route.path == link.path}" @click="gotoRoute(link.path)" )
                        b-icon(size="lg" class="mr-2" :icon="link.icon")
                        p(class=" m-0") {{link.name}}
                            b-badge(variant="success" v-if="link.new" class="ml-2") New
                        div(v-if="countAdminChatNotification > 0 && link.name==='Chat' && $route.path !=='/messaging'" class="ml-auto")
                            b-badge(variant="danger") {{countAdminChatNotification}}
                        //- div(v-if="countEventsNotification > 0 && link.name==='Event' && $route.path !=='/events'" class="ml-auto")
                        //-     b-badge(variant="danger") {{countEventsNotification}}
                div(class="footer")
                    div(class="logout bg-danger" @click="logout")
                        b-icon(icon="power" size="lg" class="mr-2")
                        p.m-0 Logout

    b-modal#modal-1(centered, title="Choose Client")
        div(v-if="!$root.changingClient")
            
            div(v-for="value in users" :key="value.id" class="client-card mr-5 w-100 " @click="changeState(value.id)" style="cursor: pointer;")
                div(class="d-flex shadow-sm my-2 p-2  align-items-center   rounded")
                    b-avatar(:src="`data:image/png;base64,${value.photo}`" @error="fallbackImg")
                    p.h4.ml-2.mb-0 {{ value.name }} {{ isActive(value.id) ? ' (Active)' : '' }}
        div(v-else)
            b-overlay.my-5(show)
                template(#overlay)
                    p.text-center Switching client... Please wait
        template(#modal-footer="{ cancel }")
            b-button(size="md",variant="info", @click="cancel" :disabled="$root.changingClient") Cancel
                
                    


                

            
                
</template>

<script>
import fallbackClientImg from '@/assets/user.png';
import { EVENTS_NAMESPACE } from "../store/modules/events/types-events";
import { mapState } from "vuex";
import dayjs from "dayjs";
import {mapMutations, mapActions } from "vuex";

export default {
    data() {
        return {
            navLinks: [
                { name: 'Home', path: '/home', icon: 'house' },
                { name: 'Announcement', path: '/announcement', icon: 'megaphone' },
                { name: 'Event', path: '/events', icon: 'calendar' },
                { name: 'Past Sessions', path: '/session', icon: 'book' },
                {name:'Invoices',path:'/invoices',icon:'file-earmark-text',new:true},
                { name: 'Contact Us', path: '/contact', icon: 'person' },
                { name: 'Settings', path: '/settings', icon: 'gear' },
                
                // { name: 'Survey', path: '/survey', icon: 'clipboard' },
                // { name: 'Chat', path: '/messaging', icon: 'chat' },
                
            ],
        }
    },
    methods: {
        ...mapMutations(["setSelectedDate", "setSelectedDate"]),
        ...mapActions(["fetchSelectedMonth"]),
        gotoRoute(path){
            if(this.$route.path === path) return;
            this.$router.replace(path);
        },
        isActive(id) {
            return this.$store.state.cli_id === id ? true : false
        },
        fallbackImg(e) {
            e.target.src = fallbackClientImg;
        },
        async changeState(id) {
            const choice = confirm("Are you sure you want to switch clients?");
            if(!choice) return;


            if (this.isActive(id)) {
                return;
            }

            this.$root.changingClient = true;

            const phone = localStorage.getItem('phone');
            const country = localStorage.getItem('country');

            const { data } = await this.$root.axios.post(`/change_client/`, {
                phone,
                country,
                client_id: id
            });

            // console.log("data after changing client: ", data);

            if (data.success) {
                await this.$root.setEverything(data, phone, country);

                const userData = data.userData;
                userData.nextSession = data.nextSession;
                // userData.chatToken = data.chatToken;
                this.$store.commit('setCliName', userData?.crb5c_client?.crb5c_no)
                // console.log('commingting navbar user data',userData)
                this.$store.commit("setCurrentUserData", [userData]);
                this.$store.commit("setUpcomingSession", data.nextSession);

                this.$store.commit("setCare", {
                    name: userData.crb5c_name,
                    email: userData.crb5c_email,
                });

                //--------
                this.$store.commit("setSelectedUserId", id);

                // this.$store.commit("setTwilio", data.chatToken);
                // await this.$root.initChat(data.chatToken);

                if (this.$route.path !== '/home') {
                await this.$router.push("/home");
                }
                this.setSelectedDate({ selectedMonth: dayjs.tz().subtract(1, "month").format("YYYY-MM") });
                this.fetchSelectedMonth({ selectedMonth: this.stateFetchingSession.selectedMonth});
                

                this.$root.changingClient = false;
                this.$bvModal.hide('modal-1')
                return true;
            }


            /*
            //console.log(message)
            let selected = id;
            this.$store.commit("setSelectedUserId", id);
            //console.log(this.$store.state.cli_id)
            this.$store.state.cli_name = name;
            //console.log(this.$store.state.cli_name)
            this.$store.state.cli_pic = photo;
            console.log(this.$store.state.caregiver);
            //console.log(this.$store.state.crb5c_photo)
            */
            

        },
        getCurrentCaregiverId() {
            if (!this?.currentUser[0]?.crb5c_fow_caregiverid) {
                return null;
            }
            return this.currentUser[0].crb5c_fow_caregiverid;
        },
        // newUpdate(){
        //     const updateVersion = localStorage.getItem("updateVersion");
        //     const phone  = localStorage.getItem("phone");
            
        //     if (!updateVersion && phone) {
        //         alert("Please login again.");
        //         this.forcelogout();
        //         localStorage.setItem("updateVersion", 'V1.8');
        //     }
        // },
        checkVersion(){
            let ver = localStorage.getItem('app_version');
            return ver !== '1.4.9' || !ver;
        },
        checkStartLoginDateIs30Days() {
            const startLoginDateInISO = localStorage.getItem("startLoginDateInISO");
            if (!startLoginDateInISO || startLoginDateInISO === "") {
                return null;
            }

            const todaysDate = dayjs();
            const differentDate = dayjs(todaysDate).diff(startLoginDateInISO, "day");
            const MAX_LOGIN_DAYS = 30;

            if (differentDate === MAX_LOGIN_DAYS) {
                return true;
            }

            return false;

        },
        logout() {
            const choice = confirm("Are you sure you want to logout?");
            if(!choice) return;
            localStorage.clear();
            this.$router.push({ path: "/login" });
        },
        forcelogout() {
            localStorage.clear();
            this.$router.push({ path: "/login" });

        }
    },
    computed: {
        ...mapState(["stateFetchingSession"]),
        ...mapState({
            currentUser: "currentUser",
            listData: state => (state[EVENTS_NAMESPACE].listData),
        }),
        countEventsNotification() {
            const presentListData = this.listData
                .filter(item => dayjs().isBefore(dayjs(item.date)));

            if (presentListData.length === 0) {
                return 0;
            }

            const listEventNotJoinYet = presentListData
                .filter(item => item.caregivers.length === 0)

            let listEventNotJoinYet2 = 0;

            if (this.getCurrentCaregiverId()) {
                listEventNotJoinYet2 = presentListData
                    .filter(item => item.caregivers.length !== 0)
                    .filter(itemFilter => itemFilter.id === this.getCurrentCaregiverId())
            }

            const totalEventNotJoinYet = listEventNotJoinYet.length + listEventNotJoinYet2.length;

            return totalEventNotJoinYet;

        },
        countAdminChatNotification() {
            const mapAdminChatMessages = this.$root.chatMessages.map(item => ({
                id: item.sid,
                name: item.author,
                message: item.body,
                admin: item.attributes?.admin,
            }));

            let count = 0;

            for (let i = mapAdminChatMessages.length - 1; i >= 0; i--) {
                if (mapAdminChatMessages[i].admin === undefined) {
                    break; //
                }
                count++;
            }

            return count;

        },
        countAllNotification() {
            const eventNotification = this.countEventsNotification;
            // const adminChatNotification = this.countAdminChatNotification;

            return eventNotification
            // adminChatNotification;
            // return eventNotification;

        },
        currentClient() {
            return this.$store.state.cli_name;
        },
        timeOfDayGreet() {
            let hour = this.$root.dayjs().hour();
            return hour < 12 ? 'Morning' : hour < 13 ? 'Afternoon' : 'Evening';
        },
        users() {
            return this.$store.state.users;
        },
        currentUserPhoto() {
            return this.$store.state?.users?.find(u => u.id === this.$store.state?.cli_id)?.photo || fallbackClientImg
        },
        caregiverName() {
            return this.$store.state?.caregiver?.name
        },
    },
    mounted() {
        if (this.checkStartLoginDateIs30Days() || this.checkVersion()) {
            alert("Login expired, please login again.");
            this.forcelogout();
        }
        // this.newUpdate();
        
    }



}
</script>



<style lang="sass" scoped>
.navbar
    height: 10vh
    background-color: #52256B
    align-items: center
    justify-content: space-between
    .back-icon
        cursor: pointer
        width: 20px
        height: 20px
        
    .welcome-text
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        color: white
        text-align: center
        margin: 0
    .navbar-toggler
        background-color: #52256B
        border: none
        color: white
        font-size: 2rem
        padding: 0
        margin: 0
        &:focus
            outline: none
    .sidebar-header
        display: flex
        flex-direction: row
        align-items: center
        margin: 1rem 0
        justify-content: space-between
        padding: 0 1rem
        height: 6%
        img
            width: 120px
            height: 50px

        .icon-close
            background-color: #52256B
            border: none
            color: white
            font-size: 2rem
            padding: 0
            margin: 0
            &:focus
                outline: none
    .sidebar-body
        
        width: 100%
        height: 90%
        display: flex
        flex-direction: column
        align-items: center
        justify-content: space-between  
        .links-container
            display: flex
            flex-direction: column
            align-items: start
            width: 100%
            .sidebar-link
                display: flex
                align-items: center
                padding: 1rem
                width: 100%
                margin: 2px 0
                &:hover
                    color:black
                    cursor: pointer
                p 
                    color:black
                    
            
            .sidebar-link:first-child
                margin-top: 0
        .footer
            display: flex
            flex-direction: column
            align-items: center
            justify-content: center
            width: 100%
            .logout
                display: flex
                flex-direction: row
                align-items: center
                justify-content: start
                background-color: #6C3D96
                color: white
                padding: 1rem
                width: 100%
                margin: 2px 0
                &:hover
                    background-color: #52256B
                    border-radius: 10px
                    cursor: pointer
                p 
                    color:white
          
            
.active
    background-color: #52256B !important
    
    cursor: pointer
    p 
        color:white

@keyframes shimmer
  0%
    background-position: -200% 0
  100%
    background-position: 200% 0            
       
.skeleton-loader
    display: flex
    align-items: center

    .skeleton-text
        width: 200px
        height: 2em
        background: linear-gradient(90deg, #A2A9B0 25%, #6C757D 50%, #565E64 75%)
        background-size: 200% 100%
        animation: shimmer 1s infinite
        border-radius: 4px
        margin: 0

.skeleton-avatar
    width: 40px
    height: 40px
    // background: linear-gradient(90deg, #6C757D 25%, #e0e0e0 50%, #f0f0f0 75%)
    background: linear-gradient(90deg, #A2A9B0 25%, #6C757D 50%, #565E64 75%)
    background-size: 200% 100%
    animation: shimmer 1s infinite
    border-radius: 50%
    margin-left: auto
    cursor: default



    



</style>