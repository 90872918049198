import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import router from "./router";
import { phoneVibrate } from "./utils/DeviceFeature";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import './style/app.scss'
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import axios from "axios";
// import { Client as ConversationsClient } from "@twilio/conversations";
import dsgIcon from "@/assets/newDsgLogo.png";
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc.js";
import timezone from "dayjs/plugin/timezone.js"; // dependent on utc plugin
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isToday from "dayjs/plugin/isToday";
import isYesterday from "dayjs/plugin/isYesterday";
import OneSignalVue from "onesignal-vue";


Vue.use(OneSignalVue);

import { ACTIONS, EVENTS_NAMESPACE, MUTATIONS } from "./store/modules/events/types-events";
import { mapState, mapMutations, mapActions } from "vuex";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.tz.setDefault("Asia/Singapore");

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);


Vue.config.productionTip = false;


const axiosPrepared = axios.create({
  baseURL: window.location.host.includes("localhost")
    ? "http://localhost:3000/api"
    : window.location.origin + "/api",
});

require('dotenv').config();

new Vue({
  router,
  store,
  async created() {
    window.addEventListener("beforeinstallprompt", (e) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.installEvent = e;
    });
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        this.hidden = true;
      } else {
        this.hidden = false;
      }
    });

    await this.loginAndFetch();
    this.setSelectedDate({ selectedMonth: dayjs.tz().subtract(1, "month").format("YYYY-MM") });
    await this.fetchSelectedMonth({ selectedMonth: this.stateFetchingSession.selectedMonth});
  },
  data() {
    return {
      installEvent:null,
      noInstallprompt:false,
      installClicked:false,
      dayjs,
      changingClient: false,
      axios: axiosPrepared,
      conversationsClient: null,
      status: null,
      cacheKey: {
        caregiver: "caregiver",
        announcements: "announcements",
      },
      state: {
        name: "",
        loggedIn: "",
        token: null,
        statusString: null,
        conversationsReady: false,
        conversations: [],
        selectedConversationSid: null,
        newMessage: "",
      },
      reInitInterval: null,
      announcements: [],
      reminders: [],
      everyoneAnnouncementRoom: "Everyone|Announcement",
      chatRoomId: "",
      myParticipantId: "",
      hidden: false,
      announcementsLoading: true,
      chatRoomReady: false,
      chatMessages: []
    };
  },
  computed: {
    installReady(){
      if(this.installClicked){
        return false;
      }
      return !!this.installEvent;
    },
    ...mapState(["stateFetchingSession"]),
    ...mapState({
        currentUser: "currentUser",
        listData: state => (state[EVENTS_NAMESPACE].listData),
    }),
  },
  methods: {
    installApp(){
      this.installClicked = true;
      this.installEvent.prompt();
      this.installEvent.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === "accepted") {
              console.log("User accepted the A2HS prompt");
          } else {
              console.log("User dismissed the A2HS prompt");
          }
      });
    },
    ...mapMutations(["setSelectedDate"]),
    ...mapMutations(EVENTS_NAMESPACE, [
      MUTATIONS.ADD_EVENT_FROM_ADMIN,
      MUTATIONS.REMOVE_EVENT_FROM_ADMIN,
      MUTATIONS.MODIFY_LIST_MAIN_LUNCH,
      MUTATIONS.MODIFY_LIST_LUNCH_BEVERAGE,
    ]),
    ...mapActions(["fetchSelectedMonth"]),
    ...mapActions(EVENTS_NAMESPACE, [
      ACTIONS.VALIDATE_LIST_DATA,
    ]),
    formatTitle(title, isEvent, groupName){
      let prefix = '';
      if (groupName) {
          prefix = prefix + '[Group]';
      }

      if (isEvent) {
          prefix = prefix + '[Event]';
      }

      return `${prefix}${prefix? ' ': ''}${title}`
  },
    getCurrentCaregiverId(){
      return this.currentUser[0].crb5c_fow_caregiverid;

    },
    formatDate(date) {
      if (!date) {
        return false;
      }
      if (this.dayjs(date).isSame)
      return this.dayjs(date).format('DD/MM/YYYY h:mm A');
    },
    sendNotif(body, title) {
      if (Notification.permission == "granted") {
        navigator.serviceWorker.getRegistration().then(async function (reg) {
          const options = {
            body,
            icon: dsgIcon,
            vibrate: [100, 50, 100],
            data: {
              dateOfArrival: Date.now(),
              primaryKey: 1,
            },
          };
          await reg?.showNotification(title, options);
          phoneVibrate();
          
          // FIX: when it clicked, cannot go to the apps
          self.addEventListener("notificationclick", (event) => {
            console.log("On notification click: ", event.notification.tag);
            event.notification.close();
            self.focus();
            window.focus();

          });
        });


      }
    },
    // initEvent(msgId, convId, body, attributes){
    //   // push event data to event vuex
    //   const payload = {
    //     ...attributes,
    //     msgId,
    //     convId,
    //     description: body,
    //   }

    //   this[ACTIONS.VALIDATE_LIST_DATA](payload);
    //   this[MUTATIONS.ADD_EVENT_FROM_ADMIN](payload);
      
    // },
    // onConnectionStateChanged(conversationsClient){
    //   conversationsClient.on("connectionStateChanged", (state) => {
    //     if (state === "connecting") {
    //       this.statusString = "Connecting to Twilio…";
    //       this.status = "default";
    //     }
    //     if (state === "connected") {
    //       this.statusString = "You are connected.";
    //       this.status = "success";
    //     }
    //     if (state === "disconnecting") {
    //       this.statusString = "Disconnecting from Twilio…";
    //       this.conversationsReady = false;
    //       this.status = "default";
    //     }
    //     if (state === "disconnected") {
    //       this.statusString = "Disconnected.";
    //       this.conversationsReady = false;
    //       this.status = "warning";
    //     }
    //     if (state === "denied") {
    //       this.statusString = "Failed to connect.";
    //       this.conversationsReady = false;
    //       this.status = "error";
    //     }
    //   });

    // },
    // async onConversationJoined(conversationsClient){
    //   conversationsClient.on(
    //     "conversationJoined",
    //     async (conversation) => {
    //       // console.log("JOINED", conversation)
    //       this.conversations = [...this.state.conversations, conversation];
    //       let messageObj = await conversation.getMessages();
    //       // console.log("MESSAGEBOX", messageObj)
    //       const st = this.$store.state;
    //       //let m = await conversation.getLastReadMessageIndex();
          
    //       if(st?.currentUser[0]?.crb5c_fow_caregiverid){
    //         const chatRoomId= `${st.currentUser[0].crb5c_fow_caregiverid}|${st.cli_id}|Chat`
    //         this.chatRoomId = chatRoomId;
    //         this.myParticipantId = this.chatRoomId.split('|').slice(0,2).join("|");
            
    //         for (let m of messageObj.items) {
    //           if (m.conversation.uniqueName.includes('|Announcement')) {
                
    //               // convId: m.conversation.uniqueName,
    //             // console.log("Conversation: ", m.conversation);
    //             // console.log("Raw state: ", m.state);
    //             // console.log("Attributes: ", m.state.attributes);
                
    //             const convId = m.conversation.uniqueName; 
    //             const { sid: msgId, body } = m.state;
    //             const { isEventConversation } = m.state.attributes;
  
    //             // Channel Id
    //             // console.log(`Channel Id for ${groupName}: `, m.conversation.sid);

    //             if(isEventConversation){
    //               this.initEvent(msgId, convId, body, m.state.attributes);
    //             }
    //             else{
    //               this.announcementsLoading = false;
                  
    //               // //---- push from local storage and unique value 
    //               // const announcementItem = localStorage.getItem(this.cacheKey.announcements);
    //               // if(announcementItem){
    //               //   const announcementDataItemFromTwilio = m.state;
    //               //   const data = JSON.parse(announcementItem);
    //               //   const findItem = data.find(item => item.sid === announcementDataItemFromTwilio.sid);

    //               //   if(!findItem){
    //               //     this.announcements.push(announcementDataItemFromTwilio);
    //               //   }

    //               // }else{
    //               //   this.announcements.push(m.state);
    //               // }
    //               // //---- push from local storage and unique value 

    //               this.announcements.push(m.state);

    //             }

    //           }
  
    //           if (m.conversation.uniqueName === chatRoomId) {
    //             this.chatRoomReady = true;
    //             this.chatMessages.push(m.state)
    //             this.chatRoomId = chatRoomId
    //             this.myParticipantId = this.chatRoomId.split('|').slice(0,2).join("|")
    //           }
    //         }
    //       }
    //     }
    //   );

    // },
    // onConversationLeft(conversationsClient){
    //   conversationsClient.on("conversationLeft", (thisConversation) => {
    //     this.conversations = [
    //       ...this.state.conversations.filter((it) => it !== thisConversation),
    //     ];
    //   });

    // },
    // onMessageAdded(conversationsClient){
    //   conversationsClient.on("messageAdded", (thisConversation) => {
    //     if(thisConversation.conversation.uniqueName.includes('|Announcement')){
    //       const convId = thisConversation.conversation.uniqueName;
    //       const { sid: msgId, body } = thisConversation.state;
    //       const { isEventConversation } = thisConversation.state.attributes;
          
    //       if(isEventConversation){
    //         this.initEvent(msgId, convId, body, thisConversation.state.attributes);
    //         this.sendNotif(body, thisConversation.state.attributes.title);
    //       }else {
    //         this.announcements.push(thisConversation.state);
    //         this.sendNotif(body, thisConversation.state.attributes.title);
    //       }
          
    //     }

    //     if (
    //       thisConversation.conversation.uniqueName ==
    //       this.everyoneAnnouncementRoom
    //     ) {
    //       this.announcements.push(thisConversation.state);
    //       this.sendNotif(thisConversation.state.body, thisConversation.state.attributes.title);
    //     }

    //     if (thisConversation.conversation.uniqueName == this.chatRoomId) {
    //       const { admin: isAdminSend } = thisConversation.state.attributes;

    //       this.chatMessages.push(thisConversation.state);
    //       if(isAdminSend){
    //         this.sendNotif(thisConversation.state.body, 'New chat message from DSG');
    //       }
    //     }
        
    //   });

    // },
    // onMessageRemoved(conversationsClient){
    //   conversationsClient.on("messageRemoved", (thisConversation) => {
    //     this.announcements = this.announcements.filter(
    //       (a) => a.sid !== thisConversation.state.sid
    //     );

    //     if(thisConversation.conversation.uniqueName.includes('|Announcement')){
    //       const { sid: msgId } = thisConversation.state;
    //       const { isEventConversation } = thisConversation.state.attributes;
          
    //       if(isEventConversation){
    //         this[MUTATIONS.REMOVE_EVENT_FROM_ADMIN](msgId);
    //       }
    //     }

    //   });

    // },
    // onMessageUpdated(conversationsClient){
    //   conversationsClient.on("messageUpdated", (c) => {
    //     // console.log("After message update: ");
    //     // const state = c.message.state;
    //     const attributes = c.message.state.attributes;

    //     if(attributes.isEventConversation){
    //       /*
    //         only reload the event page if caregiver cancel the event
    //       */
    //       const SECONDS = 500;
    //       const caregivers = attributes.caregivers;

    //       const itemCurrentCaregiverEvent = caregivers.find(item => item.id === this.getCurrentCaregiverId());
    //       if(!itemCurrentCaregiverEvent){
    //         setTimeout(() => {
    //           alert("Event canceled");
    //           location.reload();
    //         }, SECONDS);
    //       }

    //     }else{
    //       let updatedMsgIndex = this.$root.announcements.findIndex(a => c.message.state.sid === a.sid);
    //       this.$set(this.$root.announcements, updatedMsgIndex, c.message.state);
    //       this.sendNotif(c.message.state.body, `ANNOUNCEMENT UPDATE: ${c.message.state.attributes.title}`);

    //     }

    //   });

    // },
    // async onTokenAboutToExpire(conversationsClient){
    //   conversationsClient.on("tokenAboutToExpire", async () => {
    //     let phone = localStorage.getItem('phone');
    //     let data = await this.continueSession(phone);
    //     // console.log("ABOUT TO EXPIRE. RENEWED")
    //     this.conversationsClient.updateToken(data.jwt);
    //   });
    
    // },
    // onTokenExpired(conversationsClient){
    //   conversationsClient.on("tokenExpired", async () => {
    //     let phone = localStorage.getItem('phone');
    //     let data = await this.continueSession(phone);
    //     // console.log("EXPIRED, RENEWED")
    //     this.conversationsClient.updateToken(data.jwt);
    //   });

    // },
    // async initChat(token) {
    //   try {
    //     // console.log("USING TOKEN")
    //     window.conversationsClient = ConversationsClient;
  
    //     if (this.conversationsClient) {
    //       await this.conversationsClient.shutdown();
    //       // console.log("SHUT DOWN OLD CHAT CLIENT");
    //       this.conversationsClient = null;
    //       this.conversations = [];
    //       this.chatMessages = [];
    //       this.announcements = [];
    //       this.state = {
    //         name: "",
    //         loggedIn: "",
    //         token: null,
    //         statusString: null,
    //         conversationsReady: false,
    //         conversations: [],
    //         selectedConversationSid: null,
    //         newMessage: "",
    //       }
    //       this.chatRoomReady = false;
    //       this.chatRoomId = '';
    //       this.myParticipantId = ""
    //     }
        
  
    //     this.conversationsClient = new ConversationsClient(token);
  
    //     this.statusString = "Connecting to Twilio…";
  
    //     // connection
    //     this.onConnectionStateChanged(this.conversationsClient);
  
    //     // conversation
    //     await this.onConversationJoined(this.conversationsClient);
    //     this.onConversationLeft(this.conversationsClient);
  
    //     // message
    //     this.onMessageAdded(this.conversationsClient);
    //     this.onMessageRemoved(this.conversationsClient);
    //     this.onMessageUpdated(this.conversationsClient);
  
    //     // token
    //     await this.onTokenAboutToExpire(this.conversationsClient);
    //     this.onTokenExpired(this.conversationsClient);
  
    //     let a = await this.conversationsClient.getSubscribedConversations();
    //     this.state.conversations = a.items;
        
    //   } catch (error) {
    //     console.error(error);
    //   }
    // },

    setAxiosToken(token, phone, country) {
      localStorage.setItem("token", token);
      localStorage.setItem("phone", phone);
      localStorage.setItem("country", country);
      try {
        this.axios.interceptors.request.use(
          function (config) {
            config.headers["x-tq-authorization"] = token;
            return config;
          },
          function (error) {
            console.log("errror", error);
            return Promise.reject(error);
          }
        );
      } catch (e) {
        console.log("error setting token", e);
      }
    },
    async setEverything(data, phone, country) {
      let userData = {
        loggedIn: true,
        data: data.userData,
        jwt: data.jwt,
      };

      this.$root.setAxiosToken(data.jwt, phone, country);

      // const { data: chat } = await this.$root.axios.get("/chat");
      // if (chat) {
      //   userData.chatToken = wchat.token;
      // }
      this.$store.commit("setUpcomingSession", data.nextSession);
      this.$store.commit("setCurrentUserData", userData);
      // await this.initChat(data.chatToken);
    },
    async verifyOTP(phone, token, country) {
      if(!token || token === ""){
        alert("Please enter the OTP.");
        return false;
      }else{
        const { data } = await this.$root.axios.post(`/verify/`, {
          phone,
          country,
          token,
        });

        // console.log('data',data)
        if (data.success) {
          await this.setEverything(data, phone, country);
          this.$router.replace("/home");
          this.setLoginDateLocalStorage();
          localStorage.setItem('app_version', '1.4.9'); 
          location.reload();
          return true;
        }else{
          alert("Wrong OTP. Please try again.");
          this.step = 1;
          return false;
        }
      }
    },
    async continueSession(phone) {
      const { data } = await this.$root.axios.post(`/continue_session/`, {
        phone,
      });
      return data;
      // if (data.success) {
      //   let userData = {
      //     loggedIn: true,
      //     data: data.userData,
      //     jwt: data.jwt,
      //   };

      //   this.$root.setAxiosToken(data.jwt, phone);

      //   const { data: chat } = await this.$root.axios.get("/chat");
      //   if (chat) {
      //     userData.chatToken = chat.token;
      //   }

        //this.$store.commit("setCurrentUserData", userData);
    },
    handleMissingUser(){
        this.$router.push({path: "/login"});
        localStorage.clear();

    },
    async loginAndFetch() {
      try {
        const token = localStorage.getItem("token");
        const phone = localStorage.getItem("phone");
        const country = localStorage.getItem("country");
  
        const currentRouterPath = this.$route.path;
  
        if (!token || !phone || !country) {
          if(currentRouterPath === "/login"){
            return;
          }

          if(currentRouterPath === "/welcome"){
            return;
          }

          this.$router.push("/login");
          return;
        }
  
        this.$root.setAxiosToken(token, phone, country);
        // if (!this.$store.state.currentUser.loggedIn) {
        //let {data} = await this.continueSession(phone);
        //console.log(data)
        // }
  
        if (!this.$store.state.cli_id) {
          // console.log("TRIGGERED")
          const { data } = await this.$root.axios.get(`/fow_client?phone=${phone}&country=${country}`);
          //const {data2} = await this.$root.axios.get(`/upcoming_sessions`)
          // console.log("fow_client", data)
          // let result = await this.$OneSignal.login(data.crb5c_contactnumbermobile);
          // console.log(result)
          // console.log("data", data.rawUserData);
          
          if(data?.err === "User doesn't exist"){
            this.handleMissingUser();
            return;
          }
          // console.log('raw data,',data.rawUserData)
          // adding data.nextSession to rawUserData 
          data.rawUserData.forEach(item => {
            item.nextSession = data.nextSession;
          });
          
          const users = data.clients.map((c) => {
            // console.log("c", c);
            return {
              name: c.crb5c_no,
              photo: c.crb5c_photo,
              id: c.crb5c_fow_customerid,
              session: data.nextSession,
              
            };
          });

          // console.log("users: ", users);

          if (!this.$store.state.cli_id) {
            this.$store.commit("setSelectedUserId", users[0].id);
          }
  
          //new
          // const care_li = data.map(c => {
          //   return {
          //     name: c.crb5c_name,
          //     phone: c.crb5c_email
          //   }
          // })
          // const care = Array.from(care_li)[0];
  
          const userData = data.rawUserData;
          // userData.chatToken = data.twilioToken;
          // console.log('comminting raw data',userData)
          this.$store.commit("setCurrentUserData", userData);
          this.$store.commit("setUpcomingSession", data.nextSession);
  
          // await this.initChat(data.twilioToken);
  
          this.$store.commit("setCare", {
            name: data.crb5c_name,
            email: data.crb5c_email,
            surveystatus: data.crb5c_surveystatus
          });
  
          // this.$store.commit("setTwilio", data.twilioToken);
  
          //--------
          this.$store.commit('setCliName', userData[0]?.crb5c_client?.crb5c_no);
          this.$store.commit('setCliSurveyStatus', userData[0]?.crb5c_client?.crb5c_surveystatus);
          this.$store.commit("setUsers", users);
        }
        
      } catch (error) {
        console.error(error);
        // alert("Something when wrong, reloading...");
        // location.reload();
      }
    },
    setLoginDateLocalStorage(){
      const startLoginDateInISO = dayjs().toISOString();
      localStorage.setItem("startLoginDateInISO", startLoginDateInISO);

    },
    generateURL(path){
      const lowerCasePath = path.toLowerCase(); 
      const listPath = ["home", "session", "announcement", "events", "messaging", "contact"];
      const BASE_URL = `${window.location.origin}`;

      const pathItem = listPath.find(item => item === lowerCasePath);

      if(!pathItem){
        return `${BASE_URL}/${listPath[0]}`
      }

      return `${BASE_URL}/${lowerCasePath}`;

    },
    // initCacheAnnouncementData(){
    //   const announcementItem = localStorage.getItem(this.cacheKey.announcements);
    //   if(!announcementItem){
    //     return;
    //   }

    //   this.announcements = JSON.parse(announcementItem);
    //   console.log("announcements: ", this.announcements);

    // },
    // setCacheAnnouncementData(listAnnouncement){
    //   const announcementItem = localStorage.getItem(this.cacheKey.announcements);
    //   if(announcementItem){
    //     const data = JSON.parse(announcementItem);
    //     const uniqueSid = Array.from(new Set([...data.map(item => item.sid)]));
    //     const filterData = listAnnouncement.filter(item => !uniqueSid.includes(item.sid));

    //     if(filterData.length !== 0){
    //       const newData = [...data, ...filterData];
    //       const itemToStored = JSON.stringify(newData);
    //       localStorage.setItem(this.cacheKey.announcements, itemToStored);
    //     }

    //   }else{
    //     const itemToStored = JSON.stringify(listAnnouncement);
    //     localStorage.setItem(this.cacheKey.announcements, itemToStored);
    //   }

    // },
    // TODO: cache caregiver info
    // cacheCaregiverData(){
    //   // this.$store.commit("setCare", {
    //   //   name: data.crb5c_name,
    //   //   email: data.crb5c_email,
    //   // });

    //   const caregiver = localStorage.getItem(this.cacheKey.caregiver);
    //   if(!caregiver){
    //     return;
    //   }

    //   const parseCaregiver = JSON.parse(caregiver);

    //   console.log("caregiver: ", parseCaregiver);

    //   this.$store.commit("setCare", {
    //     name: caregiver.crb5c_name,
    //     email: caregiver.crb5c_email,
    //   });

    // },
    // TODO: cache events
    // TODO: cache chats
  },
  watch: {
    listData(value){
      console.log('value',value)
      try {
        this[MUTATIONS.MODIFY_LIST_MAIN_LUNCH](value);
        this[MUTATIONS.MODIFY_LIST_LUNCH_BEVERAGE](value);
        
      } catch (error) {
        console.error("Error handling mutations",error)
      }
      
      
    },
    // caregiver(value){
    //   const itemToStored = JSON.stringify(value);
    //   localStorage.setItem(this.cacheKey.caregiver, itemToStored);

    // },
    // announcements(value){
    //   this.setCacheAnnouncementData(value);

    // },
    
  },
   mounted(){
    // OneSignalInit();
    // this.cacheCaregiverData();
    // this.initCacheAnnouncementData();
    setTimeout(() => {
    if(window.matchMedia('(display-mode: standalone)').matches){
      console.log("Already in PWA")
      return;
    }
    if(!window.BeforeInstallPromptEvent){
      this.noInstallprompt = true;
      console.log("FF/SF detected")
    }

  }, 1000);
  },
  render: (h) => h(App),
  async beforeMount() {
    await this.$OneSignal.init({
        appId: process.env.VUE_APP_ONE_SIGNAL_APP_ID,
        safari_web_id: process.env.VUE_APP_SAFARI_WEB_ID,
        serviceWorkerPath: './OneSignalSDKWorker.js',
        serviceWorkerParam: { scope: '/' },
        welcomeNotification: {
          "title": "Permission Granted ",
          "message": "You can now receive notifcations from the app!",
        } 
        
      }).then(() => {
        console.log('OneSignal initialized');
      });
  }
}).$mount("#app");
