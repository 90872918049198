<template lang="pug">
div(class="d-flex w-100 p-3 flex-column main-container overflow-auto" v-if="!loading && !error")
    div(class="d-flex w-100 d-flex mb-3 justify-content-between align-items-center")
        
        h2(v-if="!searchOpen" :class="{'slideInFromLeft': !searchOpen, 'slideOutFromLeft': searchOpen}") Invoices
        button(@click="openSearch" v-if="!searchOpen" :class="{'slideInFromRight': !searchOpen, 'slideOutToRight': searchOpen}")
            b-icon(icon="search")

        div(v-else class="search-group" :class="{'slideInFromRight': searchOpen, 'slideOutFromRight': !searchOpen}")
            b-input(v-model="searchID" placeholder="Search by ID")
            button(@click="closeSearch") 
                b-icon(icon="x")
    div(class="d-flex p-2 w-100 tabs-container" v-if="!searchOpen")
        button(v-for="tab in tabs" :key="tab" @click="selectedTab = tab" :class="{'active': selectedTab === tab}") {{tab}}
    div(class="d-flex p-1 w-100" v-else)
        p.m-0 Search for invoice with ID: {{ searchID ? searchID : 'Start Typing' }}

    div(v-if="filteredInvoices.length > 0")
        div(v-for="(invoices, month) in invoicesByMonth" :key="month" class="invoice-group mt-4 px-2" )
            p.m-0(class="text-muted font-weight-light" style="font-size:0.9rem") {{ month }}
            div(v-for="invoice in invoices" :key="invoice.name" class="invoice-item mt-2 d-flex justify-content-between align-items-center"
            @click="goToInvoice(invoice.guid)"
            )
                div(class="d-flex align-items-center icon-container")
                    b-icon(icon="file-text")
                div(class="d-flex w-100 justify-content-between align-items-center text-container")
                    div(class="d-flex flex-column justify-content-between" )
                        p.m-0(style="font-size:0.9rem") {{ invoice.id }}
                        p.m-0(class="text-muted" style="font-size:0.8rem") {{getUnpaidAmount(invoice) <= 0 ? 'Paid on' : 'Due on'}} {{ getDateInvoice(invoice) }}
                    div(class="d-flex flex-column justify-content-between align-items-end" )
                        p.m-0 ${{ ((parseFloat((invoice.amount - invoice.subsidy || 0 )) + parseFloat(((invoice.amount - invoice.subsidy || 0 ) * (invoice.gst / 100)).toFixed(2))) * parseFloat(invoice.qty)).toFixed(2)}}
                        //- p.m-0(class=" text-small" :class="{'text-muted': invoice.paid, 'text-danger': !invoice.paid}") {{ invoice.paid ? 'Paid' : `$${invoice.due} due` }}
                        p.m-0(class=" text-small" :class="{'text-success': getUnpaidAmount(invoice) >= 0, 'text-danger': getUnpaidAmount(invoice) > 0}") {{ getUnpaidAmount(invoice) <= 0 ? 'Paid' : `$${getUnpaidAmount(invoice)}` }}
                div
                    b-icon(icon="arrow-right-circle")
    
    div(v-else class="d-flex w-100 h-100 flex-column align-items-center p-2 my-5")
        LottieAnimation(:animation-data="doggoData" :autoPlay="true" :loop="true" :speed="1" ref="anim"  class="w-100")
        p.m-0 No Invoices Found
                    
div(class="d-flex w-100 h-100 flex-column align-items-center p-2" v-else-if="loading")
    b-skeleton(height="10vh" width="100%" class="my-2")
    div(class="tabs-skeleton w-100 d-flex" style="height: 50px;gap:5px;" )
        b-skeleton(height="5vh" width="30%" class="my-2" v-for="i in 3")
    
        
    b-skeleton(height="10vh" width="100%" class="my-2" v-for="i in 3")
div(class="d-flex w-100 h-100 flex-column align-items-center p-2" v-else-if="error")
    lottie-animation(:animation-data="animationData" :autoPlay="true" :loop="true" :speed="1" ref="anim"  class="w-100")
    p(class="text-danger text-center") Its not you, its us. Something went wrong. Please try again later.
    b-button(@click="loadInvoices" variant="primary" class="mt-2") Try Again
                    
            

</template>

<script>
import moment from 'moment';
import axios from 'axios';
import errorAnimation from "@/assets/errorAnimation.json";
import LottieAnimation from 'lottie-web-vue'
import doggoAnimation from "@/assets/doggo.json";
export default {
    components: {
        LottieAnimation
    },
    data(){
        return {
            tabs:['Paid','Outstanding','All'],
            loading: false,
            error: false,
            selectedTab: 'Outstanding',
            invoices: [],
            animationData:errorAnimation,
            searchOpen: false,
            searchID: '',
            doggoData: doggoAnimation
        }
    },
    computed:{
        filteredInvoices() {
            if (this.selectedTab === 'All' && !this.searchID) {
                return this.invoices;
            }
            else if (this.selectedTab === 'Paid') {
                return this.invoices.filter((invoice) => {
                    const total = ((invoice.amount - invoice.subsidy || 0 ) + ((invoice.amount - invoice.subsidy || 0 ) * (invoice.gst / 100))) * invoice.qty;
                    const totalPayments = invoice.list_payment?.reduce((sum, payment) => sum + payment.amt, 0) || 0;
                    const totalCredits = invoice.list_cn?.reduce((sum, credit) => sum + credit.amt, 0) || 0;
                    const totalPaidAmount = totalPayments + totalCredits;
                    return totalPaidAmount >= total;
                });
            }
            else if(this.selectedTab==='All' && this.searchID){
                return this.invoices.filter((invoice) => {
                    return invoice.id.toString().toLowerCase().includes(this.searchID.toString().toLowerCase());
                });
            }
            else {
                return this.invoices.filter((invoice) => {
                    const total = ((invoice.amount - invoice.subsidy || 0 ) + ((invoice.amount - invoice.subsidy || 0 ) * (invoice.gst / 100))) * invoice.qty;
                    const totalPayments = invoice.list_payment?.reduce((sum, payment) => sum + payment.amt, 0) || 0;
                    const totalCredits = invoice.list_cn?.reduce((sum, credit) => sum + credit.amt, 0) || 0;
                    const totalPaidAmount = totalPayments + totalCredits;
                    return totalPaidAmount < total;
                });
            }
        },

        invoicesByMonth() {
            const groupedInvoices = this.filteredInvoices.reduce((acc, invoice) => {
                const month = moment(invoice.date).format('MMMM YYYY');
                if (!acc[month]) {
                    acc[month] = [];
                }
                acc[month].push(invoice);
                return acc;
            }, {});

            return Object.keys(groupedInvoices)
            .sort((a, b) => moment(b, 'MMMM YYYY').diff(moment(a, 'MMMM YYYY')))
            .reduce((acc, key) => {
                acc[key] = groupedInvoices[key];
                return acc;
            }, {});
        }
    },
    methods:{
        openSearch(){
            this.searchOpen = true;
            this.selectedTab='All';
        },
        closeSearch(){
            this.searchOpen = false;
            this.searchID = '';
        },
        getUnpaidAmount(invoice){
            const total = ((invoice.amount - invoice.subsidy || 0 ) + ((invoice.amount - invoice.subsidy || 0 ) * (invoice.gst / 100))) * invoice.qty;
            const totalPayments = invoice.list_payment?.reduce((sum, payment) => sum + payment.amt, 0) || 0;
            const totalCredits = invoice.list_cn?.reduce((sum, credit) => sum + credit.amt, 0) || 0;
            const totalPaidAmount = totalPayments + totalCredits;
            return total - totalPaidAmount ? (total - totalPaidAmount).toFixed(2) : 0;
        },
        getDateInvoice(invoice){
            let unPaid = this.getUnpaidAmount(invoice);
            if (unPaid > 0) {
                let date = new Date(invoice.date);
                date.setDate(date.getDate() + 30);
                return date.toLocaleDateString('en-US', {  year: 'numeric', month: 'short', day: 'numeric' })
            }else{
                return this.getDatePaid(invoice);
            }
        },
        getDatePaid(invoice){
            if (invoice.list_payment && invoice.list_payment.length > 0) {
                const latestPaymentDate = invoice.list_payment.map(payment => new Date(payment.date)).sort((a,b) => b- a)[0];
                return latestPaymentDate.toLocaleDateString('en-US', {  year: 'numeric', month: 'short', day: 'numeric' });
            }
            else{
                return new Date(invoice.date).toLocaleDateString('en-US', {  year: 'numeric', month: 'short', day: 'numeric' });
            }
        },
        goToInvoice(id){
            this.$router.replace(`/invoice_single?id=${id}`);
        },
        async loadCurrentUser() {
            while (!this.$root.currentUser[0]) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
        },
        async loadInvoices() {
            try {
                this.loading = true;
                await this.loadCurrentUser();
                const clientID = this.$root.currentUser[0].crb5c_client.crb5c_fow_customerid;
                if (!clientID) {
                    return
                }
                const invoices = await axios.get(`/api/invoices/${clientID}`)
                this.invoices = invoices.data.data;
            } catch (e) {
                this.error = true;
                console.error(e);
            } finally {
                this.loading = false;
            }


        }
    }
    ,
   async mounted(){
        await this.loadInvoices();

    }
}
</script>

<style lang="scss" scoped>
.main-container{
    height: 90vh;
    overflow-y: auto;
}
.tabs-container{
    width: 100%;
    border-radius: 0.8rem;
    background-color: #EDEEF2;
    gap: 1rem;
    
    button{
        width: 100%;
        height: 65px;
        background-color: #EDEEF2;
        border: none;
        border-radius: 0.8rem;
        cursor: pointer;
        transition: background-color 0.3s;
        
    }
    .active{
        background-color: white;
    }    
}
button{
    outline: none;
    background-color: #EDEEF2;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    width: 10%;
    border-radius: 0.4rem;
    color: black;
}
.invoice-item{
    background-color: white;
    border-radius: 0.5rem;
    height: 65px;
    gap: 1rem;
    .icon-container{
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #EDEEF2;
        border-radius: 0.5rem;
        b-icon{
            font-size: 1.5rem;
        }
    }
    .text-container{
        width: 85%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        div{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 0.5rem;
        }
    }
}
.text-small{
    font-size: 0.8rem;
}

@keyframes slideInFromRight {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideOutToRight {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slideOutToLeft {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.slideOutToRight{
    animation: slideOutToRight 0.5s;
}



.slideInFromRight{
    animation: slideInFromRight 0.5s;
}

.slideInFromLeft{
    animation: slideInFromLeft 0.5s;
}

.slideOutToLeft{
    animation: slideOutToLeft 0.5s;
}
.search-group{
    width: 100%;
    display: flex;
    gap: 0.5rem;
    b-input{
        width: 80%;
        height:100%;
    }
    button{
        width: 20%;
    }
}
</style>