import { render, staticRenderFns } from "./InvoicesPage.vue?vue&type=template&id=574ad2f8&scoped=true&lang=pug"
import script from "./InvoicesPage.vue?vue&type=script&lang=js"
export * from "./InvoicesPage.vue?vue&type=script&lang=js"
import style0 from "./InvoicesPage.vue?vue&type=style&index=0&id=574ad2f8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "574ad2f8",
  null
  
)

export default component.exports