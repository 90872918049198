import Vue from 'vue'
import VueRouter from 'vue-router'

// import LoginPage from '../views/LoginPage.vue'
// import HomePage from '../views/HomePage.vue'
import HomePageV2 from '@/views/HomePageV2.vue'
import SessionPage from '../views/SessionPage.vue'
import SessionDetail from '../views/SessionDetail.vue'
import ClientPage from '../views/ClientPage.vue'
import PaymentPage from '@/views/PaymentPage.vue'
// import AnnouncementPage from '../views/AnnouncementPage.vue'
import AnnouncementPageV2 from '@/views/AnnouncementPageV2.vue'
import ReminderPage from '../views/ReminderPage.vue'
import ContactPage from '../views/ContactPage.vue'
import MessagingPage from '../views/MessagingPage.vue'
import BillingPage from '../views/BillingPage.vue'
// import NewBillingPage from "../views/NewBillingPage.vue"
import ErrorPage from '../views/ErrorPage.vue'
import WelcomingPage from '../views/WelcomingPage.vue'
import InvoiceSingle from '../views/InvoiceSingle.vue'
// import EventPage from '../views/EventPage.vue'
import PastEventPage from "../views/PastEventsPage.vue"
import SurveyPage from "../views/SurveyPage.vue"
import LoginPageV2 from '@/views/LoginPageV2.vue'
import EventPageV2 from '@/views/EventPageV2.vue'
import EventDetails from '@/views/EventDetails.vue'
import SettingsPage from '@/views/SettingsPage.vue'
import InvoicesPage from '@/views/InvoicesPage.vue'
Vue.use(VueRouter)

const routes = [
  {path: '/', redirect: '/welcome'},
  {path: '/welcome',name: 'welcome',component: WelcomingPage},
  // {path: '/login',name: 'login',component: LoginPage},
  {path: '/login',name: 'login',component: LoginPageV2},
  {path: '/home',name: 'home', component: HomePageV2},
  {path: '/settings', name: 'settings', component: SettingsPage},
  {path: '/session',name: 'session',component: SessionPage},
  {path: '/session/:sessionId',name: 'sessiondetail',component: SessionDetail},
  {path: '/client',name: 'client',component: ClientPage},
  {path: '/contact',name: 'contact', component: ContactPage},
  {path: '/announcement',name: 'announcement',component: AnnouncementPageV2},
  {path: '/reminder',name: 'reminder',component: ReminderPage},
  {path: '/billing',name: 'billing',component: BillingPage},
  {path: '/invoices',name: 'invoices',component: InvoicesPage},
  // {path: '/billing',name: 'billing',component: NewBillingPage},
  {path: '/payment',name: 'PaymentPage',component: PaymentPage},
  {path: '/messaging',name: 'messaging',component: MessagingPage},
  {path: '/invoice_single',name: 'invoicesingle',component: InvoiceSingle},
  {path: '/events',name: 'EventPage',component: EventPageV2},
  {path: '/event/:eventId',name: 'EventDetails',component: EventDetails},
  {path: '/pastevents', name: 'PastEventsPage', component: PastEventPage},
  {path: '/survey', name: 'SurveyPage', component: SurveyPage},
  {path: '/:notFound(.*)', component:ErrorPage }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
