<template lang="pug">
    .mainBody(style="display: flex; flex-direction: column; min-height: 90vh; overflow-y: auto;")
        b-modal#paynowQR(title="Download PayNow QR" size="2xl" hide-footer style="min-width:1190px;")
            div#qrholder.my-3(class="text-center qrcode")
                figure(style="display: flex; justify-content: center; align-items: center;")
                    vue-qrcode(:value="paynowString" :options="qroptions")
                    img(src="@/assets/new_paynow.png")
                h5.text-muted(style="font-weight:500;")
                    span REF: {{invoice?.id}}
            div.mt-4
                .topbar.px-2.text-left.font-weight-light.my-4
                    p.text-dark.font-weight-normal.my-2 Download Instructions
                    p
                        | For IOS devices, click the
                        span
                            b-icon.mx-2(icon="arrow-up-square") 
                            | button to Save or Share the image.
                b-button(variant="success" @click="downloadQR" style="width:100%;") Download

        .content(style="flex-grow: 1; overflow-y: auto;" v-if="!isNaN(outstandingAmount(invoice))")
            div.my-3.mt-4(class="text-center qrcode" )
                h5.text-muted(style="font-weight:600;")
                    span REF: {{invoice?.id}}
                    
                figure(style="display: flex; justify-content: center; align-items: center;" ref='payNowQrCodre')
                    vue-qrcode(:value="paynowString" :options="qroptions")
                    img(src="@/assets/new_paynow.png")
                h5.text-dark(style="font-weight:900;")
                    span SGD {{outstandingAmount(invoice)}}
            
            
            .topbar.mt-3.px-4.text-left.font-weight-light(style="font-size: 14px;")
                p.text-dark.font-weight-normal.my-2 Payment Instructions
                p.mx-1
                    b-icon.mr-2(icon="arrow-right-circle") 
                    | Scan the QR code with a mobile banking application.
                p.mx-1
                    b-icon.mr-2(icon="arrow-right-circle") 
                    | Or save and upload the QR code to a mobile banking application.
                div.my-3
                    b-button.btn-primary-outline(v-b-modal.paynowQR variant="light" style="width:50%;") Download QR 
                        b-icon.mx-2(icon="download") 
                p.mx-1
                    b-icon.mr-2(icon="arrow-right-circle") 
                    | Click 'Done' only once payment has been made. Payment will be reflected within 5 minutes.
        
        .p-3(style="margin-top: auto;" v-if="!isNaN(outstandingAmount(invoice))")
            b-button(variant="success" @click="back" style="width:100%;") Done
        div(v-else class="d-flex w-100 h-100 flex-column justify-content-center align-items-center")
            b-skeleton(height="5vh" width="80%" class="mt-4 my-1 mx-auto")
            b-skeleton(height="30vh" width="60%" class="my-1 mx-auto")
            b-skeleton(height="5vh" width="80%" class="my-1 mx-auto")
            b-skeleton(height="5vh" width="80%" class="my-1 mx-auto")
            b-skeleton(height="20vh" width="80%" class="my-1 mx-auto")
            b-skeleton(height="5vh" width="80%" class="mt-5 mx-auto")
</template>


<script>
import dayjs from "dayjs"
import VueQrcode from '@chenfengyuan/vue-qrcode';
import PaynowQR from 'paynowqr'
import axios from 'axios'
import html2canvas from "html2canvas";
export default{
    components: {
        VueQrcode
    },
    data(){
        return{
            now: dayjs().format("DD MMMM YYYY"),
            invoice:{},
            loading: false,
        }
    },
    computed: {
        formattedDueDated() {
            return this.invoice?.date ? dayjs(this.invoice.date).add(30, 'days').format("DD-MM-YYYY") : '';
        },
        formattedDate() {
            return this.invoice?.date ? dayjs(this.invoice.date).format("DD-MM-YYYY") : '';
        },
        paynowString() {
            let qrcode = new PaynowQR({
                uen: '202111519KDSG', //Required: UEN of company
                amount: this.outstandingAmount(this.invoice),  //Required: Amount to pay
                refNumber: this.invoice.id,   //Reference number for Paynow Transaction. Useful if you need to track payments for recouncilation.
                company: 'Dementia Singapore Ltd. - Acc 1' //Company name to embed in the QR code. Optional.               
            });
            return qrcode.output();
        },
        qroptions() {
            const width = window.innerWidth > 1200 ? 400 : 250;
            const height = window.innerWidth > 1200 ? 400 : 250;
            return {
            errorCorrectionLevel: "H",
            color: {
                dark: "#7C1978"
            },
            width: width,
            height: height
            }
        }
    },
    methods:{
        async downloadQR() {
            const QR = document.querySelector('#qrholder');
            if (QR) {
            try {
                const canvas = await html2canvas(QR);
            const imageUrl = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = imageUrl;
            link.download = `Invoice_${this.invoice.id}_QR.png`;
            link.click();
            } catch (error) {
                console.error('Error while downloading QR code:', error);}
            }
        },
        outstandingAmount(invoice){
            const totalPayments = invoice?.list_payment?.reduce((sum, payment) => sum + payment.amt, 0) || 0;
            const totalCredits = invoice?.list_cn?.reduce((sum, credit) => sum + credit.amt, 0) || 0;
            const totalPaidAmount = totalPayments + totalCredits;
            return ((parseFloat((invoice?.amount - invoice?.subsidy || 0 )) + parseFloat(((invoice?.amount - invoice?.subsidy || 0 ) * (invoice?.gst / 100)).toFixed(2))) * parseFloat(invoice?.qty) - totalPaidAmount).toFixed(2)
        },
        formatDate(date){
            return dayjs(date).format("DD-MMM-YYYY")
        },
        async back(){
            await document.querySelectorAll('.mainBody').forEach(e => e.style.display = 'none')
            document.querySelectorAll('meta')[2].setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no')
            document.title = 'DSG FOW Caregiver'
            this.$router.replace('/invoices')
        },
        getQueryParams(){
            const params = new URLSearchParams(window.location.search)
            // console.log(params.get('id'))
            return params.get('id')
        },
        getInvoiceWithID(id){
            if(!id) return
            this.loading = true
            axios.get(`/api/invoices/single/${id}`)
            .then(res => {
                // console.log('invoice',res.data)
                this.invoice = res.data
            }).catch(e => {
                console.error(e)
            }).finally(() => {
                this.loading = false
            })  
        }
    },
    mounted(){
        const id= this.getQueryParams()
        this.getInvoiceWithID(id)

    },
    created(){
        document.querySelectorAll('meta')[2].setAttribute('content', 'width=device-width, initial-scale=1, maximum-scale=1, user-scalable=no')
    }
}
</script>

<style lang="scss" scoped>
.container {
    min-height: 1400px;
    min-width: 1044px !important;
}

.qrcode {
    position: relative;
}

.qrcode img {
    height: 20%;
    left: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}
.float{
    position:fixed;
    background-color:rgba(73, 75, 74, 0.721);
    color:#ffffff;
    border-radius:30px;
    border: none;
    text-align:center;
    font-size:2rem;
    z-index: 9999;
}

h5.text-muted {
  font-size: 16px;
  color: #6c757d;
  margin-top: 10px;
}

.topbar p {
  color: #6c757d;
  margin-bottom: 5px;
}
.content{
    flex-grow: 1;
    max-height: 100vh; 
    overflow-y: auto;
}

@media print {
    .container {
        break-after: always;
        break-inside: avoid;
    }

    .topbar,
    .topbar * {
        display: none !important;
        ;
    }

    .container,
    .container * {
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }
}
</style>